
import React from "react";
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {
  Container
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { ToastContainer, toast } from 'react-toastify';
const cookies = new Cookies();
class Adminlogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ifLogin: false,
      loginInfo: {
        username: "",
        password: ""
      }
    }

  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    let getusername = cookies.get('user');
    let getpassword = cookies.get('password');
    if (getusername && getpassword) {
      if (getusername === 'rahulria1991' && getpassword === 'porinoy@123##') {
        this.state.loginInfo.username = getusername;
        this.state.loginInfo.password = getpassword;
        this.setState({
          ifLogin: true,
          loginInfo: this.state.loginInfo
        })

      }
    }

  }
  loadTeam = () => {
    fetch('./API/team.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.teamlist = tempData
        this.setState({ teamlist: this.state.teamlist })
      })
      .catch(error => {
        console.log(error);
      })
  }
  onchange = (e, name) => {
    this.state.loginInfo[name] = e.target.value;
    this.setState({
      loginInfo: this.state.loginInfo
    })
  }
  doLogin = () => {
    let username = this.state.loginInfo.username;
    let password = this.state.loginInfo.password;
    if (username === '' || password === '') {
      toast.error('Fill all the field')
    } else {
      if (username === 'rahulria1991' && password === 'porinoy@123##') {
        cookies.set('user', username, { path: '/' });
        cookies.set('password', password, { path: '/' });
        window.location.reload()
      } else {
        toast.error('Username & password are wrong')
      }
    }
  }
  logout = () => {
    cookies.remove('user');
    cookies.remove('password');
    window.location.reload()
  }
  goTopage = (url) => {
    let navigate = useNavigate();
    navigate(url)
  }
  render() {

    return (
      <>
        <DemoNavbar />
        <ToastContainer />
        <main ref="main">
          <section
            style={{ backgroundImage: "url(images/inner-banner-bg.webp)", backgroundPosition: "center", backgroundSize: "cover" }}
            className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <div className="h1 text-center text-uppercase mb-0">
                  {!this.state.ifLogin ? 'Admin Login' : "Admin Dashboard"}
                  {this.state.ifLogin && <div><button onClick={() => this.logout()} className="btn btn-danger">Logout</button></div>}


                </div>
              </div>
            </div>
          </section>
          <section className="section border-top">
            <Container fluid>
              {!this.state.ifLogin && <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-4 col-xl-4 col-md-6">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div class="form-floating mb-3">
                        <input value={this.state.loginInfo.username} onChange={(e) => this.onchange(e, 'username')} type="text" class="form-control" id="floatingInput" placeholder="Username" />
                        <label for="floatingInput">Username</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input value={this.state.loginInfo.password} onChange={(e) => this.onchange(e, 'password')} type="password" class="form-control" id="floatingPassword" placeholder="Password" />
                        <label for="floatingPassword">Password</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button onClick={() => this.doLogin()} className="btn btn-danger">Login</button>
                    </div>


                  </div>
                </div>
              </div>
              }
              {this.state.ifLogin &&
                <div className="row align-items-center justify-content-center">
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-12 col-lg-3 col-xl-3 col-md-3 mb-3">
                        <div className="card shadow">
                          <div className="card-header h3 m-0 bg-warning text-white">Wedding Card</div>
                          <div className="card-body">
                            <div>
                              <Link className="d-block h5" to="/generateproductjson" >
                                Products <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                            <div>
                              <Link className="d-block h5" to="/categoriesjson" >
                                Categories <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div className="col-12 col-lg-3 col-xl-3 col-md-3 mb-3">
                        <div className="card shadow">
                          <div className="card-header h3 m-0 bg-warning text-white">Other Card</div>
                          <div className="card-body">
                            <div>
                              <Link className="d-block h5" to="/othercardjson" >
                                Products <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                            <div>
                              <Link className="d-block h5" to="/othercardcategoriesjson" >
                                Categories <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div className="col-12 col-lg-3 col-xl-3 col-md-3 mb-3">

                        <div className="card shadow">
                          <div className="card-header h3 m-0 bg-warning text-white">Accessories</div>
                          <div className="card-body ">
                            <div>
                              <Link className="d-block h5" to="/generateaccessoriesjson" >
                                Products <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                            <div>
                              <Link className="d-block h5" to="/accessoriescategoriesjson" >
                                Categories <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-12 col-lg-3 col-xl-3 col-md-3 mb-3">

                        <div className="card shadow">
                          <div className="card-header h3 m-0 bg-warning text-white">Gift Items</div>
                          <div className="card-body ">
                            <div>
                              <Link className="d-block h5" to="/generategiftitemjson" >
                                Products <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                            <div>
                              <Link className="d-block h5" to="/giftitemcategoriesjson" >
                                Categories <i class="fas fa-long-arrow-alt-right"></i>
                              </Link>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-12 col-lg-3 col-xl-3 col-md-3 mb-3">
                        <Link className="d-block" to="/recentWorkproductjson" >
                          <div className="card shadow">
                            <div className="card-body text-center">
                              <h5 className="h3 m-0">
                                Recent Work
                              </h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-12 col-lg-3 col-xl-3 col-md-3 mb-3">
                        <Link to="/testimonialproductjson" >
                          <div className="card shadow">
                            <div className="card-body text-center">
                              <h5 className="h3 m-0">
                                Testimonial
                              </h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-12 col-lg-3 col-xl-3 col-md-3">
                        <Link to="/storiesjson" >
                          <div className="card shadow">
                            <div className="card-body text-center">
                              <h5 className="h3 m-0">
                                Stories

                              </h5>
                            </div>
                          </div>
                        </Link>
                      </div>


                    </div>
                  </div>
                </div>
              }


            </Container>
          </section>

        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default Adminlogin;
