
import React from "react";
import { Helmet } from "react-helmet-async";
import { copy } from 'copy-anything'
import history from "../history";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: [ ]

    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadtestimonial()
  }
  loadtestimonial = () => {
    fetch('./API/team.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.testimonial = tempData
        this.setState({
          testimonial: this.state.testimonial
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  render() {
    return (
      <>
      <Helmet>
          <title>Bengali Marriage Invitation Card | Testimonials</title>
          <meta name="description"
            content="Happy to showcase the compliments about Porinoy Bengali Wedding Cards" />
            <meta property="og:title" content='Bengali Marriage Invitation Card | Testimonials' />
            <meta property="og:description" content='Happy to showcase the compliments about Porinoy Bengali Wedding Cards' />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
        <section
        style={{backgroundImage:"url(images/inner-banner-bg.webp)", backgroundPosition:"center", backgroundSize:"cover"}}
        className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <h3 style={{fontWeight:"500"}} className="h1 text-center text-uppercase mb-0">Teams</h3>
              </div>
            </div>
          </section>
          <section style={{paddingBottom:"2.5rem"}} className="section border-top">
            <Container fluid>
              <div className="row">
                {this.state.testimonial.map((item, index) => {
                  let ifLocation = false
                   if (item.clientLocation !== '') {
                    ifLocation = true
                  }
                  return <div className="col-12 col-lg-3 col-xl-3 col-md-4 mb-4" key={index}> 
                   <div className="card h-100 bg-transparent border-0">
                    <div className="card-body p-0 text-center">
                      <div className="row">
                        <div className="col-12">
                          <img className=" d-inline teamroundedimg w-100" src={item.thumb} />
                        </div>

                        <div className="col-12 mt-4 h5 mb-0">
                          {item.name}
                        </div>
                        <div className="col-12  mb-0">
                          ({item.position})
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                })}

              </div>
            </Container>
          </section>
        </main >
        <SimpleFooter />

      </>
    );
  }
}

export default Testimonial;
