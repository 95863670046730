
import React from "react";
import { Link, NavLink } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import GiftItemsCategory from "views/GiftItemsCategory";
class DemoNavbar extends React.Component {
  componentDidMount() {
    //  let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    // headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
    categories: [],
    accessoriesCategories: [],
    giftItemsCategory: [],
    otherCategory:[]
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };
  componentDidMount() {
    this.loadCategories()
    this.loadAccessoriesCategories()
    this.loadgiftItemsCategory()
    this.loadOtherCardCategory()
  }
  loadCategories = () => {
    fetch('../API/categories.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false;
        })
        this.state.categories = tempData
        this.setState({
          categories: this.state.categories
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadAccessoriesCategories = () => {
    fetch('../API/accessoriesCategory.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false;
        })
        this.state.accessoriesCategories = tempData
        this.setState({
          accessoriesCategories: this.state.accessoriesCategories
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadgiftItemsCategory = () => {
    fetch('../API/giftItemsCategory.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false;
        })
        this.state.giftItemsCategory = tempData
        this.setState({
          giftItemsCategory: this.state.giftItemsCategory
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadOtherCardCategory = () => {
    fetch('../API/categoriesOtherCard.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false;
        })
        this.state.otherCategory = tempData
        this.setState({
          otherCategory: this.state.otherCategory
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  goToDetails = (id, type) => {
    if (type === 'product') {
      window.location = '/product-category/' + id
    }
    if (type === 'accessories') {
      window.location = '/accessories-category/' + id
    }
    if (type === 'giftitems') {
      window.location = '/giftitems-category/' + id
    }
    if (type === 'othercard') {
      window.location = '/othercard-category/' + id
    }
  }
  render() {
    return (
      <>
        <div id="topBar" className="topBar">
          <Container fluid className="h-100">
            <Row className="h-100 align-items-center">
              <Col className="col-lg-4 col-xl-4 col-md-4 col-12 text-center text-lg-left text-xl-left text-md-left" >
                Call us now: +91-9832888069
              </Col>
              {/* <Col className="col-4 text-center d-none d-md-block d-lg-block d-xl-block">
                WELCOME TO PORINOY!
              </Col> */}
              <Col className="col-8 text-right d-none  d-lg-block d-xl-block">
                <ul className="topbar-nav m-0">
                  <li class="nav-item d-inline-block">
                    <NavLink to="/about-us">About Us</NavLink>
                  </li>
                  <li class="nav-item d-inline-block">
                    <NavLink to="/teams">Teams</NavLink>
                  </li>
                  <li class="nav-item d-inline-block">
                    <NavLink to="/testimonials">Testimonials</NavLink>
                  </li>
                  <li class="nav-item d-inline-block">
                    <NavLink to="/stories">Stories</NavLink>
                  </li>
                  <li class="nav-item d-inline-block">
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </li>
                </ul>


              </Col>
            </Row>
          </Container>

        </div>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container fluid>
              <NavbarBrand className="mr-lg-5 position-relative  d-flex align-items-center justify-content-center" to="/" tag={Link}>
                <span className="logoRound">
                  <img
                    alt="..."
                    src={require("assets/img/logo.png")}
                  />
                </span>

              </NavbarBrand>
              <button className="navbar-toggler float-right" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses + " justify-content-end"}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/logo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center ml-lg-auto main-nav" navbar>
                  <NavItem>
                    <NavLink exact to="/">Home</NavLink>
                  </NavItem>
                  <NavItem className="d-block d-lg-none d-xl-none">
                    <NavLink to="/about-us">About Us</NavLink>
                  </NavItem>
                  <NavItem className="d-block d-lg-none d-xl-none">
                    <NavLink to="/teams">Teams</NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink to="/products">Collection</NavLink>
                  </NavItem> */}
                  <UncontrolledDropdown

                    direction="down"
                    nav>
                    <DropdownToggle
                      className="px-0"
                      caret
                      color="primary"
                      nav>
                      <span className="nav-link-inner--text">Wedding Card Collection</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/products" tag={Link}>
                        All Collection
                      </DropdownItem>
                      {this.state.categories.map((item, index) => {
                        return <DropdownItem className="text-capitalize" onClick={() => this.goToDetails(item.id, 'product')}>
                          {item.name}
                        </DropdownItem>
                      })}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown

                    direction="down"
                    nav>
                    <DropdownToggle
                      className="px-0"
                      caret
                      color="primary"
                      nav>
                      <span className="nav-link-inner--text">Other Card Collection</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/othercard" tag={Link}>
                        All Collection
                      </DropdownItem>
                      {this.state.otherCategory.map((item, index) => {
                        return <DropdownItem className="text-capitalize" onClick={() => this.goToDetails(item.id, 'othercard')}>
                          {item.name}
                        </DropdownItem>
                      })}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown
                    direction="down"
                    className="me-0"
                    nav>
                    <DropdownToggle
                      className="px-0 "
                      caret
                      color="primary"
                      nav>
                      <span className="nav-link-inner--text">Wedding Accessories</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/accessories" tag={Link}>
                        All Wedding Accessories
                      </DropdownItem>
                      {this.state.accessoriesCategories.map((item, index) => {
                        return <DropdownItem className="text-capitalize" onClick={() => this.goToDetails(item.id, 'accessories')}>
                          {item.name}
                        </DropdownItem>
                      })}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {/* <UncontrolledDropdown
                    direction="down"
                    className="me-0 down-end"
                    nav>
                    <DropdownToggle
                      className="px-0"
                      caret
                      color="primary"
                      nav>
                      <span className="nav-link-inner--text">Gift Items</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/gifitems" tag={Link}>
                        All Gift Items
                      </DropdownItem>
                      {this.state.giftItemsCategory.map((item, index) => {
                        return <DropdownItem className="text-capitalize" onClick={() => this.goToDetails(item.id, 'giftitems')}>
                          {item.name}
                        </DropdownItem>
                      })}

                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                  <NavItem className="d-block d-lg-none d-xl-none">
                    <NavLink to="/testimonials">Testimonials</NavLink>
                  </NavItem>
                  <NavItem className="d-block d-lg-none d-xl-none">
                    <NavLink to="/stories">Stories</NavLink>
                  </NavItem>
                  <NavItem className="d-block d-lg-none d-xl-none">
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </NavItem>

                </Nav>

              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
