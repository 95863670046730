import React, { useEffect } from "react";
import { Link, NavLink as CNavLink } from "react-router-dom";
// reactstrap components
import Slider from "react-slick";
import NextArrow from "../SliderNav/NextNav";
import PrevArrow from "../SliderNav/PrevNav";
const RelatedProduct = ({ data, itemSHow, itemSHowMD, itemSHowsm, itemSHowXS }) => {
  let items = data;
  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    speed: 500,
    rows: 2,
    slidesToShow: itemSHow,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: itemSHowMD,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: itemSHowsm,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: itemSHowXS,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      }
    ]
  };

  const goToDetails = (id) => {
    window.location = '/accessories-details/' + id
  }
  return (
    <div className="row">


      {/* <Slider {...settings}> */}
      {items.map((item, index) => {
        if (index < 8) {
          return <div key={index} className="col-12 mb-3  col-lg-4 col-xl-3 col-md-6">
            <div className="text-left h-100" key={index}>
            <div className="cursor-pointer " onClick={(e) => goToDetails(item.id)}>
              <div className="mb-2 overflow-hidden position-relative">
                <img width="100%" className="opacity" src="../images/thumb-container.png" />
                <img width="100%" className="position-absolute start-0 top-0" src={"../" + item.thumb} />
              </div>
              <div >
                <div className="row m-0">
                  <div style={{ fontSize: "16px" }} className="col pl-0 h5 mb-0">
                    {item.name}
                  </div>
                
                </div>
              </div>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                <i class="fa-solid fa-indian-rupee-sign"></i> {item.price}

              </div>
            </div>
          </div>
          </div>
        }

      })}
      {/* </Slider> */}



    </div>
  );
}
export default RelatedProduct;