import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import history from "../history";
import Home from "./Home.js";
import Stories from "./Stories.js";
import Products from "./Products.js";
import Accessories from "./Accessories.js";
import GiftItems from "./GiftItems.js"
import ProductDetails from "./ProductDetails.js";
import AccessoriesDetails from "./AccessoriesDetails.js";
import GiftItemsDetails from "./GiftItemsDetails.js";
import StoriesDetails from "./StoriesDetails.js";
import ProductsJsonEdit from "./ProductsJsonEdit.js"
import RecentJsonEdit from "./RecentJsonEdit.js"
import TestimonialJsonEdit from "./TestimonialJsonEdit.js"
import StoriesJsonEdit from "./StoriesJsonEdit.js"
import CategoryJsonEdit from "./CategoryJsonEdit.js"
import AccessoriesCategoryJsonEdit from "./AccessoriesCategoryJsonEdit.js"
import GiftItemsCategoryJsonEdit from "./GiftItemsCategoryJsonEdit.js"
import GiftItemsJsonEdit from "./GiftItemsJsonEdit.js"
import AccessoriesJsonEdit from "./AccessoriesJsonEdit.js"
import Adminlogin from "./Adminlogin.js"
import About from "./About.js";
import Contact from "./Contact.js";
import Testimonial from "./Testimonial.js";
import Terms from "./Terms.js"
import Privacy from "./Privacy.js"
import ProductCat from "./ProductsCategory.js"
import AccessoriesCat from "./AccessoriesCategory.js"
import GiftItemsCategory from "./GiftItemsCategory.js"
import OtherCard from "./OtherCard.js"
import OtherCardCategory from "./OtherCardCategory.js"
import OtherCardDetails from "./OtherCardDetails.js"
import OtherCardJsonEdit from "./OtherCardJsonEdit.js"
import OtherCardCategoryJsonEdit from "./OtherCardCategoryJsonEdit.js"
import Teams from "./Teams.js"
import NotFound from "./NotFound.js"
import Tool from "./Tool.js";

const cookies = new Cookies();
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ifLogin: false,
            loginInfo: {
                username: "",
                password: ""
            }
        }
    }
    componentDidMount() {

        window.scrollTo(0, 0);
        let getusername = cookies.get('user');
        let getpassword = cookies.get('password');
        if (getusername && getpassword) {
            if (getusername === 'rahulria1991' && getpassword === 'porinoy@123##') {
                this.setState({
                    ifLogin: true,
                    loginInfo: { username: getusername, password: getpassword }
                })
            }
        }
    }
    render() {

        return (
            <>
                <BrowserRouter history={history}>
                    <Routes>
                        <Route path="/" exact element={<Home />} />
                        <Route path="/404" exact element={<NotFound />} />
                        <Route path="/tool" exact element={<Tool />} />
                        <Route path="/terms-condition" exact element={<Terms />} />
                        <Route path="/privacy-policy" exact element={<Privacy />} />
                        <Route path="/admin-login" exact element={<Adminlogin />} />
                        <Route path="/stories" exact element={<Stories />} />
                        <Route path="/teams" exact element={<Teams />} />
                        <Route path="/stories-details/:id" exact element={<StoriesDetails />} />
                        <Route path="/product-category/:id" exact element={<ProductCat />} />
                        <Route path="/othercard-category/:id" exact element={<OtherCardCategory />} />
                        
                        <Route path="/accessories-category/:id" exact element={<AccessoriesCat />} />
                        <Route path="/giftitems-category/:id" exact element={<GiftItemsCategory />} />
                        <Route path="/about-us" exact element={<About />} />
                        <Route path="/contact-us" exact element={<Contact />} />
                        <Route path="/testimonials" exact element={<Testimonial />} />
                        <Route path="/gifitems" exact element={<GiftItems />} />
                        <Route path="/accessories" exact element={<Accessories />} />
                        <Route path="/products" exact element={<Products />} />
                        <Route path="/othercard" exact element={<OtherCard />} />
                        <Route path="/product-details/:id" exact element={<ProductDetails />} />
                        <Route path="/othercard-details/:id" exact element={<OtherCardDetails />} />
                        <Route path="/accessories-details/:id" exact element={<AccessoriesDetails />} />
                        <Route path="/giftitems-details/:id" exact element={<GiftItemsDetails />} />
                        {this.state.ifLogin && <Route path="/generateproductjson" exact element={<ProductsJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/othercardjson" exact element={<OtherCardJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/generateaccessoriesjson" exact element={<AccessoriesJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/generategiftitemjson" exact element={<GiftItemsJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/recentWorkproductjson" exact element={<RecentJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/testimonialproductjson" exact element={<TestimonialJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/storiesjson" exact element={<StoriesJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/categoriesjson" exact element={<CategoryJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/accessoriescategoriesjson" exact element={<AccessoriesCategoryJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/giftitemcategoriesjson" exact element={<GiftItemsCategoryJsonEdit />} />}
                        {this.state.ifLogin && <Route path="/othercardcategoriesjson" exact element={<OtherCardCategoryJsonEdit />} />}
                        <Route path="*" element={<Navigate to="/404" replace />} />
                    </Routes>
                </BrowserRouter>
            </>
        )
    }
}
export default App
