
import React from "react";
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import RelatedProduct from "components/RelatedAccessories/RelatedProduct.js"
import VideoModal from "components/Video/Index"
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      relatedProducts: [],
      categories: [],
      videoModal: false,
      videoUrl: '',
      quantity: '1',
      productname: "",
      nav1: null,
      nav2: null,
      customerDetailsModal: false,
      customerDetails: [
        { name: 'occationType', label: "Occasion Type", value: 'Wedding', mandatory: false, type: 'select', error: false },
        { name: 'occationDate', label: "Occasion Date", value: "", mandatory: false, type: 'date', error: false },
        { name: 'name', label: "Name", value: '', mandatory: true, type: 'text', error: false },
        { name: 'address', label: "Address", value: '', mandatory: false, type: 'text', error: false },
        { name: 'city', label: "City", value: '', mandatory: false, type: 'text', error: false },
        { name: 'state', label: "State", value: '', mandatory: false, type: 'text', error: false },
        { name: 'pin', label: "Pin Code", value: '', mandatory: true, type: 'pincode', error: false }
      ],
      customerDetailsSubmit: false,
      printrate: [
        { unit: '1' },
        { unit: '2' },
        { unit: '3' },
        { unit: '4' },
        { unit: '5' },
        { unit: '6' },
        { unit: '7' },
        { unit: '8' },
        { unit: '9' },
        { unit: '10' },
        { unit: '11' },
        { unit: '12' },
        { unit: '13' },
        { unit: '14' },
        { unit: '15' },
        { unit: '16' },
        { unit: '17' },
        { unit: '18' },
        { unit: '19' },
        { unit: '20' },
        { unit: '21' },
        { unit: '22' },
        { unit: '23' },
        { unit: '24' },
        { unit: '25' }
      ]
    }
  }
  toggle = () => {
    this.setState({
      videoModal: !this.state.videoModal
    })
  }
  openModal = (url) => {
    this.setState({
      videoModal: true,
      videoUrl: url
    })
  }
  openModalCustomerDetail = (url) => {
    this.setState({
      customerDetailsModal: true
    })
  }
  toggleModalCustomerDetail = () => {
    this.setState({
      customerDetailsModal: !this.state.customerDetailsModal
    })
  }
  loadPrintCgrges = () => {
    return false
    fetch('../API/printrate.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;

        this.state.printrate = tempData
        this.setState({ printrate: this.state.printrate })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadCategories = () => {
    fetch('../API/giftItemsCategory.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false
        })
        this.state.categories = tempData
        this.setState({ categories: this.state.categories })
      })
      .catch(error => {
        console.log(error);
      })
  }
  componentDidMount() {
    this.loadPrintCgrges()
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    let { id } = this.props.params;
    this.loadCategories()
    this.loadProduct(id)
    const body = document.querySelector('#topBar');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)

  }
  getFilterDataProduct = (data, param, val) => {
    return data.filter((items) => items[param].some(cat => cat === val))
  }
  loadProduct = (id) => {
    fetch('../API/giftItems.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        let getProduct = tempData.filter((item) => item.id === id);
        let relatedProduct = [];
        let getProductCat = getProduct[0].category;
        if (getProductCat.length) {
          getProductCat.forEach((item, index) => {
            let getAllCatproduct = this.getFilterDataProduct(tempData, "category", item);
            getAllCatproduct.forEach((catitem, catindex) => {
              if (catitem.id !== id) {
                relatedProduct.push(catitem)
              }
            })
          })
        }


        if (getProduct[0].color.length) {
          getProduct[0].color.forEach((item) => {
            item.checked = false
          });
          getProduct[0].color[0].checked = true
        }
        getProduct[0].gallery.forEach((item) => {
          item.images.forEach((image) => {
            image.checked = false
          })
          item.images[0].checked = true
        })
        this.state.product = getProduct
        this.setState({
          product: this.state.product,
          relatedProducts: relatedProduct,
          productname: getProduct[0].name
        })
        setTimeout(() => {
          const body = document.querySelector('#topBar');
          body.scrollIntoView({
            behavior: 'smooth'
          }, 500)
        }, 10);

      })
      .catch(error => {
        console.log(error);
      })
  }
  orderProduct = () => {
    this.openModalCustomerDetail()

  }
  isValidPinCode = (str) => {
    // Regex to check valid
    // Pincode of India
    let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);

    // if str
    // is empty return false
    if (str == null) {
      return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return "true";
    }
    else {
      return "false";
    }
  }
  submitCutomerForm = () => {
    if (this.state.customerDetails[2].value === '') {
      this.state.customerDetails[2].error = true
    } else {
      this.state.customerDetails[2].error = false;
      console.log(this.state.customerDetails[1].value)
    }
    if (this.state.customerDetails[6].value === '') {
      this.state.customerDetails[6].error = true
    } else {
      let isvalid = this.isValidPinCode(this.state.customerDetails[6].value)
      if (isvalid !== "true") {
        this.state.customerDetails[6].error = true
      } else {
        this.state.customerDetails[6].error = false
      }
    }
    let checkError = [];
    this.state.customerDetails.forEach((item) => {
      if (item.mandatory) {
        if (item.error) {
          checkError.push(item.name)
        }
      }
    })

    this.setState({
      customerDetailsSubmit: true,
      customerDetails: this.state.customerDetails
    })
    if (checkError.length === 0) {
      this.orderProductFinal()
      console.log(this.state.customerDetails)

    }

  }
  orderProductFinal = () => {
    let url = 'https://wa.me/+919832888069?text='
    let message = "New Order from https://porinoy.in %0a"
    message = message + "Gift Item ID - " + this.state.product[0].id + "%0a";
    message = message + "URL - https://www.porinoy.in/giftitems-details/" + this.state.product[0].id + "%0a";
    message = message + "Quantity - " + this.state.quantity + "%0a";
    this.state.customerDetails.forEach((item, index) => {
      if (index !== 0 && index !== 1) {
        if (item.value !== '') {
          message = message + item.label + " - " + item.value + "%0a";
        }
      }
    })

    url = url + message
    window.open(url)
    this.toggleModalCustomerDetail()

  }
  changeImage = (imgind) => {
    this.state.product[0].gallery[0].images.forEach((item) => {
      item.checked = false
    })
    this.state.product[0].gallery[0].images[imgind].checked = true;
    this.setState({
      product: this.getUniqueItem(this.state.product)
    })
  }
  getUniqueItem = (data) => {
    const keys = ['id', 'name'];
    const filteredData = data.filter((value, index, self) =>
      self.findIndex(v => keys.every(k => v[k] === value[k])) === index
    )
    return filteredData
  }
  changeQuantity = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  onchange = (e, ind) => {
    let name = e.target.name;
    let val = e.target.value;
    this.state.customerDetails[ind].value = val
    if (this.state.customerDetailsSubmit) {
      console.log(this.state.customerDetails[ind])
      if (this.state.customerDetails[ind].mandatory === true) {
        if (ind !== 6) {
          if (this.state.customerDetails[ind].value === '') {
            this.state.customerDetails[ind].error = true
          } else {
            this.state.customerDetails[ind].error = false
          }
        } else {
          if (this.state.customerDetails[ind].value === '') {
            this.state.customerDetails[ind].error = true
          } else {
            let isvalid = this.isValidPinCode(this.state.customerDetails[ind].value)
            if (isvalid !== "true") {
              this.state.customerDetails[ind].error = true
            } else {
              this.state.customerDetails[ind].error = false
            }
          }
        }

      }

    }
    this.setState({
      customerDetails: this.state.customerDetails
    })
  }
  render() {
    let ifProduct = false;
    let galimgcount = 0;
    if (this.state.product.length) {
      ifProduct = true
      galimgcount = this.state.product[0].gallery[0].images.length
    }
    let bigimg = "";
    let ifcolorVar = false;
    if (ifProduct) {
      // if (this.state.product[0].color.length) {
      //   ifcolorVar = true
      // }

      // if (ifcolorVar) {
      //   let selectedColor = "";
      //   this.state.product[0].color.forEach((item) => {
      //     if (item.checked) {
      //       selectedColor = item.name
      //     }
      //   })
      //   let getSelectedgallery = this.state.product[0].gallery.filter((item) => item.color === selectedColor);
      //   console.log(getSelectedgallery)
      //   let selectedImages = getSelectedgallery[0].images;
      //   console.log(selectedImages)
      //   selectedImages.forEach((item) => {
      //     if (item.checked) {
      //       bigimg = item.url
      //     }
      //   })
      //   console.log(bigimg)
      // }
      if (!ifcolorVar) {
        let selectedImages = this.state.product[0].gallery[0].images;
        selectedImages.forEach((item) => {
          if (item.checked) {
            bigimg = item.url
          }
        })
      }
    }
    let ifrelatedprod = false;
    if (this.state.relatedProducts.length) {
      ifrelatedprod = true
    }

    return (
      <>
        <Helmet>
          <title>{"Porinoy Cards | " + this.state.productname}</title>
          <meta name="description"
            content="Find the collection of premium wedding card, simple wedding card, hindu wedding invitation cards." />
          <meta property="og:title" content={"Porinoy Cards | " + this.state.productname} />
          <meta property="og:description" content='Find the collection of premium wedding card, simple wedding card, hindu wedding invitation cards.' />
        </Helmet>
        <DemoNavbar />
        <main ref="main">

          <section className="section py-3 pt-5 border-top">
            <Container fluid>
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-6 col-md-12">
                  <div className="border">
                    <div className="row">
                      <div className="col-12">
                        <div style={{ padding: ".25rem" }} className="text-center position-relative">
                          <Slider
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            arrows={false}
                            infinite={galimgcount > 2 ? true : false}

                          >
                            {ifProduct && this.state.product[0].gallery[0].images.map((images, imgIndex) => {
                              return <div key={imgIndex} className=" " >
                                <div >
                                  <div className="position-relative">
                                    <img alt="" width="100%" className="opacity d-block " src="../images/thumb-container.png" />

                                    <img alt={images.alt} width="100%" className="position-absolute start-0 top-0" src={"../" + images.url} />
                                  </div>
                                </div>
                              </div>
                            })}
                          </Slider>
                          {this.state.product.length > 0 && this.state.product[0].videoUrl !== "" && <> <span
                            onClick={() => this.openModal(this.state.product[0].videoUrl)}
                            style={{
                              zIndex: "1",
                              right: "15px",
                              bottom: "15px",
                              background: 'rgba(255, 255,255,.5)',
                              color: '#000',
                              lineHeight: "40px",
                              width: "40px",
                              height: "40px",
                              textAlign: "center",
                              borderRadius: "50%",
                              cursor: 'pointer'

                            }}
                            className="videolink position-absolute">
                            <i class="fa-solid fa-play"></i>
                          </span>
                          </>}
                        </div>
                      </div>
                      <div className="col-12">
                        {ifProduct && <div className={galimgcount > 2 ? 'border border-top-0 multi' : 'border border-top-0 single'} >

                          {this.state.product.map((item, ind) => {
                            return <div key={ind}>
                              <div className="pdetailthumb" style={{ paddingBottom: ".25rem" }}>
                                <Slider
                                  asNavFor={this.state.nav1}
                                  ref={slider => (this.slider2 = slider)}
                                  slidesToShow={galimgcount > 2 ? 3 : galimgcount}
                                  swipeToSlide={true}
                                  focusOnSelect={true}
                                  infinite={galimgcount > 2 ? true : false}
                                >
                                  {item.gallery[0].images.map((images, imgIndex) => {
                                    return <div key={imgIndex} className="position-relative float-left cursor-pointer  detailThumb " >
                                      <div style={{ padding: "0px .25rem" }}>
                                        <div style={{ maxWidth: "225px" }} className="position-relative">
                                          <img alt="" width="100%" className="opacity d-block " src="../images/thumb-container.png" />

                                          <div className="seletedImgOverlay"></div>

                                          <img alt={images.alt} className="position-absolute start-0 top-0" src={"../" + images.url} />
                                        </div>
                                      </div>
                                    </div>
                                  })}
                                </Slider>
                              </div>


                            </div>

                          })}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 col-md-12">

                  {ifProduct && <div>
                    {this.state.product.map((item, ind) => {
                      // let ifcolor = false;
                      let ifpaper = false;
                      let ifwidth = false;
                      let ifheight = false;
                      let ifweight = false;
                      if (item.width !== "") {
                        ifwidth = true
                      }
                      if (item.height !== "") {
                        ifheight = true
                      }
                      if (item.weight !== "") {
                        ifweight = true
                      }
                      // if (item.color.length) {
                      //   ifcolor = true
                      // }
                      if (item.paper !== "") {
                        ifpaper = true
                      }
                      let ifcategory = false;
                      if (item.category.length) {
                        ifcategory = true
                      }
                      return <div key={ind}>
                        <div style={{ fontSize: "1.6rem", fontWeight: "600" }} className="h1">{item.name}</div>
                        <div className="h3"><i class="fa-solid fa-indian-rupee-sign"></i> {item.price}</div>

                        <div className="mb-2">{item.description}</div>
                        <div >
                          <div className="row">

                            <div className="col-6 mb-2">
                              <div style={{ maxWidth: "100px" }}>
                                <label className="h5">Quantity</label>
                                <select value={this.state.quantity} onChange={(e) => this.changeQuantity(e)} name="quantity" className="form-select">
                                  {this.state.printrate.map((item, ind) => {
                                    return <option value={item.unit} key={ind}>{item.unit}</option>
                                  })}
                                </select>

                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="mb-2">
                          <button onClick={() => this.orderProduct()} className="btn btn-danger btn-large">Order On Whatsapp</button>
                        </div>
                        <div className="bg-adiinfo p-2 rounded mb-2">
                          <div className="row">
                            <div className="col-12 mb-2">
                              <div className="row">
                                <div className="col-12"><div className="h5">Additional Information</div></div>
                                {ifweight && <div className="col-4">
                                  <div className="h6">Weight</div>
                                  <div>{item.weight} Grams</div>
                                </div>}
                                {ifwidth && <div className="col-4">
                                  <div className="h6">Width</div>
                                  <div>{item.width} CMS</div>
                                </div>}
                                {ifheight && <div className="col-4">
                                  <div className="h6">Height</div>
                                  <div>{item.height} CMS</div>
                                </div>}

                              </div>
                            </div>
                          </div>
                        </div>

                        {ifcategory &&
                          <>
                            <div className="h5">Category</div>
                            <div className="mb-2">
                              {item.category.map((catitem, catIndex) => {
                                let getcat = this.state.categories;
                                console.log(getcat)
                                console.log(catitem)
                                let filterCat = getcat.filter((cats => cats.id === catitem));
                                if (filterCat.length) {
                                  return <div className="d-inline-block text-capitalize border px-2 py-1 mx-1 my-1 rounded" key={catIndex}>{filterCat[0].name}</div>
                                }

                              })}
                            </div>
                          </>
                        }

                      </div>

                    })}

                  </div>}
                </div>
              </div>
              <Modal centered size="lg" isOpen={this.state.videoModal} toggle={this.toggle}>
                <ModalBody>
                  <span onClick={this.toggle} className="closeModal"><i class="fa-solid fa-circle-xmark"></i></span>
                  <VideoModal videoUrl={this.state.videoUrl} />
                </ModalBody>

              </Modal>
              <Modal centered size="lg" isOpen={this.state.customerDetailsModal} toggle={this.toggleModalCustomerDetail}>
                <ModalBody>
                  <span onClick={this.toggleModalCustomerDetail} className="closeModal"><i class="fa-solid fa-circle-xmark"></i></span>
                  <div className="row">
                    {/* <div className="col-12 col-lg-4 col-xl-4 ">
                      <div class="form-floating mb-3">
                        <select
                          onChange={(e) => this.onchange(e, 0)}
                          value={this.state.customerDetails[0].value}
                          placeholder={this.state.customerDetails[0].label}
                          className="form-select"
                        >
                          <option className="Wedding">Wedding</option>
                          <option className="Reception">Reception</option>
                        </select>
                        <label >{this.state.customerDetails[0].label} </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e, 1)} value={this.state.customerDetails[1].value} type="date" class={this.state.customerDetails[1].error === true ? "form-control is-invalid" : "form-control"} placeholder={this.state.customerDetails[1].label} />
                        <label >{this.state.customerDetails[1].label} <span className="text-danger">*</span></label>
                      </div>
                    </div> */}
                    <div className="col-12">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e, 2)} value={this.state.customerDetails[2].value} type="text" class={this.state.customerDetails[2].error === true ? "form-control is-invalid" : "form-control"} placeholder={this.state.customerDetails[2].label} />
                        <label >{this.state.customerDetails[2].label} <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e, 3)} value={this.state.customerDetails[3].value} type="text" class="form-control" placeholder={this.state.customerDetails[3].label} />
                        <label >{this.state.customerDetails[3].label} </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e, 4)} value={this.state.customerDetails[4].value} type="text" class="form-control" placeholder={this.state.customerDetails[4].label} />
                        <label >{this.state.customerDetails[4].label} </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e, 5)} value={this.state.customerDetails[5].value} type="text" class="form-control" placeholder={this.state.customerDetails[5].label} />
                        <label >{this.state.customerDetails[5].label} </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4 ">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchange(e, 6)} value={this.state.customerDetails[6].value} type="number" class={this.state.customerDetails[6].error === true ? "form-control is-invalid" : "form-control"} placeholder={this.state.customerDetails[6].label} />
                        <label >{this.state.customerDetails[6].label} <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-xl-4 ">
                      <button onClick={() => this.submitCutomerForm()} className="btn btn-danger">Submit</button>
                    </div>
                  </div>
                </ModalBody>

              </Modal>
            </Container>
          </section>
          <section className="section p-3 pt-5 border-top">
            <Container fluid>
              <Row>
                <Col className="text-center">
                  <div className="h1 mb-4">
                    Related Products
                  </div>
                </Col>
              </Row>
              {ifrelatedprod && <RelatedProduct itemSHow={4} itemSHowMD={3} itemSHowsm={2} itemSHowXS={1} data={this.state.relatedProducts} />}
            </Container>
          </section>
        </main>
        <SimpleFooter />

      </>
    );
  }
}

export default withParams(ProductDetails);
