import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
// reactstrap components
import Slider from "react-slick";
import NextArrow from "../SliderNav/NextNavTop";
import PrevArrow from "../SliderNav/PrevNavTop";
const Catagories = ({ data, itemSHow, itemSHowMD, itemSHowsm, itemSHowXS }) => {
  let items = data;
  const settings = {
    infinite: true,
    slidesToShow: itemSHow,
    speed: 500,
    rows: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: itemSHowMD,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: itemSHowsm,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: itemSHowXS,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
        }
      }
    ]
  };
  let navigate = useNavigate()
  const goToDetails = (id) => {
    navigate('/accessories-details/' + id)
  }
  return (
    <div className="row">
      <div className="col-12">
        <Slider {...settings}>
          {data.map((item, index) => {
            return <div className="px-1 homecatProd" key={index}>
              <div className="cursor-pointer " onClick={(e) => goToDetails(item.id)}>
                <div className="overflow-hidden position-relative">
                  <img alt="" width="100%" className="opacity" src="images/thumb-container.png" />
                  
                  <img alt={item.thumbAlt} width="100%" className="position-absolute start-0 top-0 thumb-enlarge" src={item.thumb} />
                  <div className="position-absolute catprod-price shadow">Price <i class="fa-solid fa-indian-rupee-sign"></i> {item.price}</div>
                </div>
              </div>
            </div>
          })}
        </Slider>
      </div>
    </div>

  );

}
export default Catagories;