import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
// reactstrap components
import Slider from "react-slick";
import NextArrow from "../SliderNav/NextNavTop";
import PrevArrow from "../SliderNav/PrevNavTop";
const BestSellerProduct = ({ data, itemSHow, itemSHowMD, itemSHowsm, itemSHowXS,maxproduct }) => {
  let items = data;
  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: itemSHow,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: itemSHowMD,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: itemSHowsm,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: itemSHowXS,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      }
    ]
  };
  let navigate = useNavigate()
  const goToDetails = (id) => {
    navigate('/product-details/' + id)
  }
  return (
    <div className="row">
      <div className="col-12">
        <div>
          <Slider {...settings}>
            {items.map((item, index) => {
              let maxCOunt = items.length - 1
              if(maxproduct){
                maxCOunt = maxproduct
              }
              if (maxCOunt >= index) {
                console.log()
                return <div className="px-2 text-left" key={index}>
                  <div className="cursor-pointer " onClick={(e) => goToDetails(item.id)}>
                    <div className="mb-2 overflow-hidden position-relative">
                      <img width="100%" className="opacity" src="images/thumb-container.png" />
                      <img alt={item.thumbAlt} width="100%" className="position-absolute start-0 top-0" src={item.thumb} />
                    </div>
                    <div >
                      <div className="row m-0">
                        <div style={{ fontSize: "16px" }} className="col pl-0 h5 mb-0">
                          {item.name}
                        </div>
                        {/* <div className="col-auto pr-0">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </div> */}
                      </div>
                    </div>
                    <div style={{ fontSize: "23px", fontWeight: "600" }}>
                      <i class="fa-solid fa-indian-rupee-sign"></i> {item.price}

                    </div>
                  </div>
                </div>
              }
            })}
          </Slider>
        </div>
      </div>

    </div>
  );
}
export default BestSellerProduct;