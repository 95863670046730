
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { saveAs } from 'file-saver';
import { copy } from 'copy-anything'
import {
  Button,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
class ProductsJsonEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productEdit: false,
      products: [],
      productsOriginal: [],
      selectedProduct: [],
      selectedProductIndex: null,
      categories: [],
      neproductModal: false,
      newProduct: [{
        "name": "",
        "outOfstock": false,
        "description": "",
        "id": "",
        "videoUrl": "",
        "category": [],
        "paper": "",
        "width": "",
        "height": "",
        "weight": "",
        "thumb": "",
        "thumbAlt": "",
        "price": 0,
        "color": [],
        "gallery": [
          {
            "images": []
          }
        ]
      }]

    }
    this.exportRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadProduct();
    this.loadCategories()

  }
  loadProduct = () => {
    fetch('./API/giftItems.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        this.state.products = tempData
        this.setState({
          products: this.state.products,
          productsOriginal: copy(tempData)
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  loadCategories = () => {
    fetch('./API/giftItemsCategory.json', {
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let tempData = responseJson;
        tempData.forEach((item) => {
          item.checked = false
        })
        this.state.categories = tempData
        this.setState({ categories: this.state.categories })
      })
      .catch(error => {
        console.log(error);
      })
  }
  editProduct = (ind) => {
    let selpro = [];

    let allproduct = copy(this.state.products)
    selpro.push(allproduct[ind])
    this.setState({
      selectedProduct: selpro,
      productEdit: true,
      selectedProductIndex: ind
    })
  }
  toggle = () => {
    this.setState({
      productEdit: false
    })
  }
  addNewProuctJson = async () => {
    let allproducts = this.state.products;
    allproducts.push(this.state.newProduct[0])
    this.setState({
      products: allproducts
    })
    let jsonfile = JSON.stringify(allproducts, undefined, 2);
    let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    saveAs(fileToSave, 'giftItems.json');
  }
  updateJson = async () => {
    let allproducts = this.state.products;
    if (this.state.selectedProductIndex !== null) {
      allproducts[this.state.selectedProductIndex] = this.state.selectedProduct[0]
    }
    this.setState({
      products: allproducts
    })
    let jsonfile = JSON.stringify(allproducts, undefined, 2);
    let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    saveAs(fileToSave, 'giftItems.json');
  }
  generateJson = () => {
    let allproducts = this.state.products;
    let jsonfile = JSON.stringify(allproducts, undefined, 2);
    let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    saveAs(fileToSave, 'giftItems.json');
  }
  duplicateProduct = (index) => {
    let copyProduct = copy(this.state.products[index])
    copyProduct.name = copyProduct.name + '-duplicate'
    copyProduct.id = this.generateNumericId(20)
    let allproducts = this.state.products;
    allproducts.push(copyProduct)
    this.setState({
      products: allproducts
    })
    // let jsonfile = JSON.stringify(allproducts, undefined, 2);
    // let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    // saveAs(fileToSave, 'products.json');
  }
  /// update Product
  onchangeCat = (e, ind, catind, catname, catid) => {
    let selProduct = copy(this.state.selectedProduct);
    let getAllCat = selProduct[ind].category;
    let filterALlCat = getAllCat.filter((item) => item === catid);
    if (filterALlCat.length) {
      let updatedCat = getAllCat.filter((item) => item !== catid);
      this.state.selectedProduct[ind].category = updatedCat
      this.setState({
        selectedProduct: this.state.selectedProduct
      })
    } else {
      getAllCat.push(catid);
      this.state.selectedProduct[ind].category = getAllCat
      this.setState({
        selectedProduct: this.state.selectedProduct
      })
    }
  }
  onchangeGalleryImage = (e, ind, galIndex) => {
    this.state.selectedProduct[ind].gallery[0].images[galIndex].url = e.target.value
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  onchangeGalleryAlt = (e, ind, galIndex) => {
    this.state.selectedProduct[ind].gallery[0].images[galIndex].alt = e.target.value
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  onchange = (e, ind, keyname) => {
    this.state.selectedProduct[ind][keyname] = e.target.value;
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  delGalleryItem = (ind, galInd) => {
    let selProduct = copy(this.state.selectedProduct);
    let getAllGallery = selProduct[ind].gallery[0].images;
    let filtergetAllGallery = getAllGallery.filter((_, index) => index !== galInd);
    this.state.selectedProduct[ind].gallery[0].images = filtergetAllGallery;
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  addupdateproductGallerImage = () => {
    let selProduct = copy(this.state.selectedProduct);
    let getAllGallery = selProduct[0].gallery[0].images;
    let newImage = {
      "url": "",
      "alt": ""
    }
    getAllGallery.push(newImage)
    this.state.selectedProduct[0].gallery[0].images = getAllGallery;
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }

  /// new Product
  onchangeCatNew = (e, ind, catind, catname, catid) => {
    let selProduct = copy(this.state.newProduct);
    let getAllCat = selProduct[ind].category;
    let filterALlCat = getAllCat.filter((item) => item === catid);
    if (filterALlCat.length) {
      let updatedCat = getAllCat.filter((item) => item !== catid);
      this.state.newProduct[ind].category = updatedCat
      this.setState({
        newProduct: this.state.newProduct
      })
    } else {
      getAllCat.push(catid);
      this.state.newProduct[ind].category = getAllCat
      this.setState({
        newProduct: this.state.newProduct
      })
    }
  }
  onchangeGalleryImageNew = (e, ind, galIndex) => {
    this.state.newProduct[ind].gallery[0].images[galIndex].url = e.target.value
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  onchangeGalleryAltNew = (e, ind, galIndex) => {
    this.state.newProduct[ind].gallery[0].images[galIndex].alt = e.target.value
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  onchangeNew = (e, ind, keyname) => {
    this.state.newProduct[ind][keyname] = e.target.value;
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  delGalleryItemNew = (ind, galInd) => {
    let selProduct = copy(this.state.newProduct);
    let getAllGallery = selProduct[ind].gallery[0].images;
    let filtergetAllGallery = getAllGallery.filter((_, index) => index !== galInd);
    this.state.newProduct[ind].gallery[0].images = filtergetAllGallery;
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  addnewproductGallerImage = () => {
    let selProduct = copy(this.state.newProduct);
    let getAllGallery = selProduct[0].gallery[0].images;
    let newImage = {
      "url": "",
      "alt": ""
    }
    getAllGallery.push(newImage)
    this.state.newProduct[0].gallery[0].images = getAllGallery;
    this.setState({
      newProduct: this.state.newProduct
    })
  }

  addNewProductModal = () => {
    this.setState({
      neproductModal: true
    })
  }
  addNewProductModalClose = () => {
    this.setState({
      neproductModal: false
    })
  }
  onchangeOutofSTockNew = (e, ind, name) => {
    this.state.newProduct[ind][name] = !this.state.newProduct[ind][name];
    this.setState({
      newProduct: this.state.newProduct
    })
  }
  onchangeOutofSTock = (e, ind, name) => {
    this.state.selectedProduct[ind][name] = !this.state.selectedProduct[ind][name];
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  deleteProduct = (ind, id) => {
    let getallProduct = this.state.products;
    let filterProduct = getallProduct.filter((item) => item.id !== id);
    this.setState({
      products: filterProduct
    })
    let jsonfile = JSON.stringify(filterProduct, undefined, 2);
    let fileToSave = new Blob([jsonfile], { type: 'application/json' });
    saveAs(fileToSave, 'accessories.json');

  }
  generateNumericId = (length) => {
    let result = '';
    const digits = '0123456789';
    for (let i = 0; i < length; i++) {
      result += digits.charAt(Math.floor(Math.random() * digits.length));
    }
    return result;
  }
  updateId = (ind, keyname) => {
    this.state.selectedProduct[ind][keyname] = this.generateNumericId(20);
    this.setState({
      selectedProduct: this.state.selectedProduct
    })
  }
  addNewId = (ind, keyname) => {
    this.state.newProduct[ind][keyname] = this.generateNumericId(20);
    this.setState({
      newProduct: this.state.newProduct
    })
  }

  render() {
    let totalCouunt = 0;
    let ifProductLoaded = false
    if (this.state.products.length) {
      ifProductLoaded = true;
      totalCouunt = this.state.products.length
    }
    let ifselProductLoaded = false
    if (this.state.selectedProduct.length) {
      ifselProductLoaded = true
    }
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section
            style={{ backgroundImage: "url(images/inner-banner-bg.webp)", backgroundPosition: "center", backgroundSize: "cover" }}
            className="section pageTitleBG">
            <div className="row m-0">
              <div className="col-12">
                <div className="h1 text-center text-uppercase mb-0">Products</div>
                <div className="text-center ">
                  <NavLink className="btn btn-danger" to="/admin-login">Back</NavLink>
                  <button onClick={() => this.generateJson()} className="btn btn-danger">Generate JSON</button>
                  <button onClick={() => this.addNewProductModal()} className="btn btn-danger">New  Gift Item</button>
                </div>
              </div>
            </div>
          </section>
          <section className="section border-top">
            <Container fluid>
              <div className="row">
                <div className="col-12 mb-3">
                  <h4>Total Product <span class="badge badge-dark">{totalCouunt}</span></h4>
                </div>
                {ifProductLoaded &&
                  <div className="col-12">
                    <div className="row">
                      {this.state.products.map((item, index) => {
                        let ifCategory = false;
                        let ifGallery = false;
                        if (item.category.length) {
                          ifCategory = true
                        }
                        if (item.gallery.length) {
                          ifGallery = true
                        }
                        return <div key={index} className="col-3 mb-2">
                          <div className="card  border shadow h-100">
                            <div style={{ padding: "10px" }} className="card-header">{item.name}</div>
                            <div style={{ padding: "10px" }} className="card-body">
                              <div className="row">
                                <div className="col-12">ID# {item.id}</div>
                                <div className="col-12">
                                  <div className="overflow-hidden position-relative">
                                    <img width="100%" className="opacity" src="images/thumb-container.png" />
                                    <img className="position-absolute start-0 top-0 w-100" src={item.thumb} />
                                    {item.outOfstock &&
                                      <span className="outofstock">Out Of Stock</span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ padding: "10px" }} className="card-footer">
                              <button style={{ paddingLeft: "1rem", paddingRight: "1rem" }} onClick={() => this.editProduct(index)} className="btn btn-danger">Edit</button>
                              <button style={{ paddingLeft: "1rem", paddingRight: "1rem" }} onClick={() => this.duplicateProduct(index)} className="btn btn-danger">Duplicate</button>
                              <button style={{ paddingLeft: "1rem", paddingRight: "1rem" }} onClick={() => this.deleteProduct(index, item.id)} className="btn btn-dark"><i class="fa-solid fa-xmark"></i></button>
                            </div>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>
                }

              </div>
            </Container>
          </section>
        </main>
        <SimpleFooter />
        <Modal className="fullscreen" centered size="xl" isOpen={this.state.productEdit} toggle={this.toggle}>
          {ifselProductLoaded &&
            <ModalHeader toggle={this.toggle}>Update {this.state.selectedProduct[0].name}</ModalHeader>
          }
          <ModalBody>
            {ifselProductLoaded &&
              <div className="row">
                {this.state.selectedProduct.map((item, index) => {
                  let ifCategory = false;
                  let ifGallery = false;
                  if (item.category.length) {
                    ifCategory = true
                  }
                  if (item.gallery.length) {
                    ifGallery = true
                  }
                  return <div key={index} className="col-12">
                    <div className="row">
                      <div className="col-12 text-end">
                        <div className="form-check d-inline-block me-3 ms-1 mb-3">
                          <input checked={item.outOfstock} onChange={(e) => this.onchangeOutofSTock(e, index, 'outOfstock')} id="outOfstock" type="checkbox" class="form-check-input" />
                          <label htmlFor="outOfstock" className="form-check-label">
                            Out Of Stocke
                          </label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'name')} value={item.name} type="text" class="form-control" placeholder="Name" />
                          <label >Name <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="input-group mb-3">
                          <div class="form-floating flex-grow-1">
                            <input disabled
                              // onChange={(e) => this.onchange(e, index, 'id')}
                              value={item.id} type="text" class="form-control" placeholder="ID" />
                            <label >ID <span className="text-danger">*</span></label>
                          </div>
                          <button onClick={() => this.updateId(index, 'id')} class="input-group-text btn btn-danger">Generate</button>
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'videoUrl')} value={item.videoUrl} type="text" class="form-control" placeholder="Video URL" />
                          <label >Video URL <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div class="form-floating mb-3">
                          <textarea onChange={(e) => this.onchange(e, index, 'description')} value={item.description} style={{ height: "auto" }} rows={5} type="text" class="form-control" placeholder="Description" />
                          <label >Description <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div class="form-floating mb-3">
                          <div><label >Category <span className="text-danger">*</span></label></div>

                          <>
                            {this.state.categories.map((cat, catInd) => {
                              let allCategory = item.category;
                              let filterCategory = allCategory.filter((fcat) => fcat === cat.id);
                              if (filterCategory.length) {
                                return <div key={catInd} className="form-check d-inline-block me-3 ms-1">
                                  <input defaultChecked onChange={(e) => this.onchangeCat(e, index, catInd, cat.name, cat.id)} value={cat} type="checkbox" class="form-check-input" />
                                  <label className="form-check-label">
                                    {cat.name}
                                  </label>
                                </div>
                              } else {
                                return <div key={catInd} className="form-check d-inline-block me-3 ms-1">
                                  <input onChange={(e) => this.onchangeCat(e, index, catInd, cat.name, cat.id)} value={cat} type="checkbox" class="form-check-input" />
                                  <label className="form-check-label">
                                    {cat.name}
                                  </label>
                                </div>
                              }
                            })}

                          </>



                        </div>
                      </div>

                      <div className="col-2">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'width')} value={item.width} type="number" class="form-control" placeholder="Width" />
                          <label >Width <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-2">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'height')} value={item.height} type="number" class="form-control" placeholder="Height" />
                          <label >Height <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-2">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'weight')} value={item.weight} type="number" class="form-control" placeholder="Weight" />
                          <label >Weight <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-2">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'price')} value={item.price} type="number" class="form-control" placeholder="Price" />
                          <label >Price <span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div><label >Thumbnail Image <span className="text-danger">*</span></label></div>
                      </div>
                      <div className="col-4">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'thumb')} value={item.thumb} type="text" class="form-control" placeholder="Thumbnail" />
                          <label >Thumbnail <span className="text-danger">*</span></label>
                          <div>
                            <img className="mt-1 border" src={item.thumb} height="75" />
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="form-floating mb-3">
                          <input onChange={(e) => this.onchange(e, index, 'thumbAlt')} value={item.thumbAlt} type="text" class="form-control" placeholder="Thumbnail ALt alt" />
                          <label >Thumbnail alt Text<span className="text-danger">*</span></label>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div>
                          <label >Gallery Images <span className="text-danger">*</span></label>
                          <button onClick={(e) => this.addupdateproductGallerImage()} style={{ padding: "0.25rem 1rem" }} className="btn btn-primary ms-3"><i class="fa-solid fa-plus"></i></button>
                        </div>
                      </div>
                      {ifGallery && <>
                        {item.gallery[0].images.map((gal, galIndex) => {
                          return <div key={galIndex} className="col-4 mb-4 ">
                            <div className="card shadow">
                              <div className="card-body">
                                <button onClick={() => this.delGalleryItem(index, galIndex)} className="btnDelGal btn-danger"><i class="fa-solid fa-trash-can"></i></button>
                                <div class="form-floating mb-2">
                                  <input onChange={(e) => this.onchangeGalleryImage(e, index, galIndex)} value={gal.url} type="text" class="form-control" placeholder="Image Url" />
                                  <label >Image Url <span className="text-danger">*</span></label>

                                </div>
                                <div class="form-floating ">
                                  <input onChange={(e) => this.onchangeGalleryAlt(e, index, galIndex)} value={gal.alt} type="text" class="form-control" placeholder="Alt Text" />
                                  <label >Image Alt Text <span className="text-danger">*</span></label>

                                </div>
                                <div>
                                  <img className="mt-1 border" src={gal.url} height="75" />
                                </div>
                              </div>

                            </div>
                          </div>
                        })}
                      </>}

                    </div>

                  </div>
                })}
              </div>
            }
          </ModalBody>
          <ModalFooter className="justify-content-start">
            <button onClick={() => this.updateJson()} className="btn btn-danger">Update</button>
          </ModalFooter>
        </Modal>
        <Modal className="fullscreen" centered size="xl" isOpen={this.state.neproductModal} toggle={this.addNewProductModalClose}>

          <ModalHeader toggle={this.addNewProductModalClose}>New Gift Item</ModalHeader>

          <ModalBody>

            <div className="row">
              {this.state.newProduct.map((item, index) => {
                let ifCategory = false;
                let ifGallery = false;
                if (item.category.length) {
                  ifCategory = true
                }
                if (item.gallery.length) {
                  ifGallery = true
                }
                return <div key={index} className="col-12">
                  <div className="row">
                    <div className="col-12 text-end">
                      <div className="form-check d-inline-block me-3 ms-1 mb-3">
                        <input checked={item.outOfstock} onChange={(e) => this.onchangeOutofSTockNew(e, index, 'outOfstock')} id="outOfstockNew" type="checkbox" class="form-check-input" />
                        <label htmlFor="outOfstockNew" className="form-check-label">
                          Out Of Stocke
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'name')} value={item.name} type="text" class="form-control" placeholder="Name" />
                        <label >Name <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-4">
                        <div class="input-group mb-3">
                          <div class="form-floating flex-grow-1">
                            <input disabled
                              // onChange={(e) => this.onchange(e, index, 'id')}
                              value={item.id} type="text" class="form-control" placeholder="ID" />
                            <label >ID <span className="text-danger">*</span></label>
                          </div>
                          <button onClick={() => this.addNewId(index, 'id')} class="input-group-text btn btn-danger">Generate</button>
                        </div>
                      </div>
                  
                    <div className="col-4">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'videoUrl')} value={item.videoUrl} type="text" class="form-control" placeholder="Video URL" />
                        <label >Video URL <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-floating mb-3">
                        <textarea onChange={(e) => this.onchangeNew(e, index, 'description')} value={item.description} style={{ height: "auto" }} rows={5} type="text" class="form-control" placeholder="Description" />
                        <label >Description <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-floating mb-3">
                        <div><label >Category <span className="text-danger">*</span></label></div>

                        <>
                          {this.state.categories.map((cat, catInd) => {
                            let allCategory = item.category;
                            let filterCategory = allCategory.filter((fcat) => fcat === cat.id);
                            if (filterCategory.length) {
                              return <div key={catInd} className="form-check d-inline-block me-3 ms-1">
                                <input defaultChecked onChange={(e) => this.onchangeCatNew(e, index, catInd, cat.name, cat.id)} value={cat} type="checkbox" class="form-check-input" />
                                <label className="form-check-label">
                                  {cat.name}
                                </label>
                              </div>
                            } else {
                              return <div key={catInd} className="form-check d-inline-block me-3 ms-1">
                                <input onChange={(e) => this.onchangeCatNew(e, index, catInd, cat.name, cat.id)} value={cat} type="checkbox" class="form-check-input" />
                                <label className="form-check-label">
                                  {cat.name}
                                </label>
                              </div>
                            }
                          })}

                        </>



                      </div>
                    </div>

                    <div className="col-2">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'width')} value={item.width} type="number" class="form-control" placeholder="Width" />
                        <label >Width <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-2">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'height')} value={item.height} type="number" class="form-control" placeholder="Height" />
                        <label >Height <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-2">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'weight')} value={item.weight} type="number" class="form-control" placeholder="Weight" />
                        <label >Weight <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-2">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'price')} value={item.price} type="number" class="form-control" placeholder="Price" />
                        <label >Price <span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div><label >Thumbnail Image <span className="text-danger">*</span></label></div>
                    </div>
                    <div className="col-4">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'thumb')} value={item.thumb} type="text" class="form-control" placeholder="Thumbnail" />
                        <label >Thumbnail <span className="text-danger">*</span></label>
                        <div>
                          <img className="mt-1 border" src={item.thumb} height="75" />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div class="form-floating mb-3">
                        <input onChange={(e) => this.onchangeNew(e, index, 'thumbAlt')} value={item.thumbAlt} type="text" class="form-control" placeholder="Thumbnail ALt alt" />
                        <label >Thumbnail alt Text<span className="text-danger">*</span></label>
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div>
                        <label >Gallery Images <span className="text-danger">*</span></label>
                        <button onClick={(e) => this.addnewproductGallerImage()} style={{ padding: "0.25rem 1rem" }} className="btn btn-primary ms-3"><i class="fa-solid fa-plus"></i></button>
                      </div>
                    </div>
                    {ifGallery && <>
                      {item.gallery[0].images.map((gal, galIndex) => {
                        return <div key={galIndex} className="col-4 mb-4 ">
                          <div className="card shadow">
                            <div className="card-body">
                              <button onClick={() => this.delGalleryItemNew(index, galIndex)} className="btnDelGal btn-danger"><i class="fa-solid fa-trash-can"></i></button>
                              <div class="form-floating mb-2">
                                <input onChange={(e) => this.onchangeGalleryImageNew(e, index, galIndex)} value={gal.url} type="text" class="form-control" placeholder="Image Url" />
                                <label >Image Url <span className="text-danger">*</span></label>

                              </div>
                              <div class="form-floating ">
                                <input onChange={(e) => this.onchangeGalleryAltNew(e, index, galIndex)} value={gal.alt} type="text" class="form-control" placeholder="Alt Text" />
                                <label >Image Alt Text <span className="text-danger">*</span></label>

                              </div>
                              <div>
                                <img className="mt-1 border" src={gal.url} height="75" />
                              </div>
                            </div>

                          </div>
                        </div>
                      })}
                    </>}

                  </div>

                </div>
              })}
            </div>

          </ModalBody>
          <ModalFooter className="justify-content-start">
            <button onClick={() => this.addNewProuctJson()} className="btn btn-danger">Save</button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ProductsJsonEdit;
