import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import VideoModal from "../Video/Index"
const ProductLoop = ({ data, showPerPage }) => {
    const [videoModal, setVideoModal] = React.useState(false)
    const [videoUrl, setVideoUrl] = React.useState('')
    let navigate = useNavigate();
    const goToDetails = (id) => {
        navigate('/othercard-details/' + id)
    }
    const toggle = () => {
        setVideoModal(!videoModal)
    }
    const openModal = (url) => {
        setVideoUrl(url)
        setVideoModal(true)
    }
    return (
        <>
            <div className="row">
                {data.map((item, index) => {
                    if (showPerPage >= (index + 1)) {
                        return <div key={index} className="col-12 col-lg-4 col-xl-4 col-md-6 mb-3">
                            <div className="cursor-pointer" >
                                <div className="mb-2 overflow-hidden position-relative">
                                    {item.videoUrl !== "" && <>   <span
                                        onClick={() => openModal(item.videoUrl)}
                                        style={{
                                            zIndex: "99",
                                            right: "10px",
                                            bottom: "10px",
                                            background: 'rgba(255, 255,255,.5)',
                                            color: '#000',
                                            lineHeight: "40px",
                                            width: "40px",
                                            height: "40px",
                                            textAlign: "center",
                                            borderRadius: "50%"

                                        }}
                                        className="videolink position-absolute"><i class="fa-solid fa-play"></i></span> </>}
                                    <img alt="" onClick={(e) => goToDetails(item.id)} width="100%" className="opacity" src="images/thumb-container.png" />
                                    <img alt={item.thumbAlt} onClick={(e) => goToDetails(item.id)} width="100%" className="position-absolute start-0 top-0" src={item.thumb} />
                                    {item.outOfstock &&
                                        <span className="outofstock">Out Of Stock</span>
                                    }
                                </div>
                                <div onClick={(e) => goToDetails(item.id)}>{item.name}</div>
                                <div onClick={(e) => goToDetails(item.id)}><i class="fa-solid fa-indian-rupee-sign"></i> {item.price}</div>
                            </div>
                        </div>
                    }
                })}

            </div>
            <Modal centered size="lg" isOpen={videoModal} toggle={toggle}>
                <ModalBody>
                    <span onClick={toggle} className="closeModal"><i class="fa-solid fa-circle-xmark"></i></span>
                    <VideoModal videoUrl={videoUrl} />
                </ModalBody>

            </Modal>
        </>
    )
}
export default ProductLoop;